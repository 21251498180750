import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api interface
export default class UniteApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(search: uniteSearch): Promise<ApiResult<Pagging<unite>>> {
    return this.instance.post('/Unites/search', search)
  }

  async get(id: number): Promise<ApiResult<unite>> {
    return this.instance.get(`/Unites/${id}`)
  }

  async create(newUnite: uniteCreate): Promise<ApiResult<unite>> {
    return this.instance.post('/Unites', newUnite)
  }

  async update(unite: uniteUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Unites', unite)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Unites', { params: { id } })
  }
}

// Types
enum UniteTypeEnum {
  Sante = 'sante',
  Prevoyance = 'prevoyance',
  Retraite = 'retraite',
  Salaire = 'salaire'
}

export type UniteEnumAsKey = keyof typeof UniteTypeEnum

enum UniteSortByEnum {
  Id = 'Id',
  Name = 'Name',
  Type = 'Type',
}

type uniteCreate = {
  name: string
  type: UniteEnumAsKey
}

type uniteUpdate = {
  id: number
  name: string
  type: UniteEnumAsKey
}

type uniteSearch = {
  orderByDes?: boolean
  sortBy?: UniteSortByEnum
  name?: string
  type?: UniteEnumAsKey
  page: number
  limit: number
}

/*
 * Output
 */
type unite = {
  id: number
  name: string
  type: UniteEnumAsKey
}

type unitePreview = {
  id: number
  name: string
}

export { unite, unitePreview, uniteCreate, uniteUpdate, uniteSearch, UniteTypeEnum, UniteSortByEnum }
