import randomstring from 'randomstring'
import { encode as base64encode } from 'base64-arraybuffer'
import axios from 'axios'
import qs from 'qs'
import { env } from '@/env'

enum Provider {
  TRIPALIO = 1,
}

type OAuthConfig = {
  authorizePoint: string
  tokenPoint: string
  logoutPoint: string
  logoutRedirect: string
  params: {
    client_id: string
    redirect_uri: string
    include_granted_scopes: boolean
    access_type: string
    response_type: string
    scope: string
  }
}

type OAuthAuthorize = {
  params: {
    state: string
    code_challenge: string
    code_challenge_method: string
  }
} & OAuthConfig

function randomGenerator(length: number): string {
  return randomstring.generate(length)
}

async function computeSHA256Base64(clearCode: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(clearCode)
  const digest = await window.crypto.subtle.digest('SHA-256', data)
  const toBase64 = base64encode(digest)
  return toBase64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

function saveInStorage(key: string, value: any) {
  localStorage.setItem(key, value)
}

function getInStorage(key: string) {
  return localStorage.getItem(key)
}

function sendFormAuthorizeOpenId(service: OAuthAuthorize) {
  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('action', service.authorizePoint)
  for (const p in service.params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', (service.params as any)[p])
    form.appendChild(input)
  }
  document.body.appendChild(form)
  form.submit()
}

function sendFormLogoutOpenId(service: OAuthConfig, token: string) {
  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('action', service.logoutPoint)
  const params = {
    id_token_hint: token,
    post_logout_redirect_uri: service.logoutRedirect,
  }
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', (params as any)[p])
    form.appendChild(input)
  }
  document.body.appendChild(form)
  form.submit()
}

class OAuthClient {
  static async Authorize(config: OAuthConfig, provider: Provider): Promise<void> {
    // 1- Generate a random clearCode and random state
    const clearCode = randomGenerator(128)
    const state = randomGenerator(32)

    // 2 - Generate challengeCode
    const challengeCode = await computeSHA256Base64(clearCode)

    // 3 - save clearCode && state
    saveInStorage(`state-${provider}`, state)
    saveInStorage(`clear-code-${provider}`, clearCode)

    // 4 - Authorize
    const authorizeConfig = config as OAuthAuthorize
    authorizeConfig.params.state = state
    authorizeConfig.params.code_challenge = challengeCode
    authorizeConfig.params.code_challenge_method = 'S256'
    sendFormAuthorizeOpenId(authorizeConfig)
  }

  static async GetToken(code: string, state: string, provider: Provider, config: OAuthConfig): Promise<void> {
    const savedState = getInStorage(`state-${provider}`)
    if (savedState !== state) {
      throw Error('Probable session hijacking attack!')
    }
    const clearCode = getInStorage(`clear-code-${provider}`)

    return axios
      .post(
        config.tokenPoint,
        qs.stringify({
          client_id: config.params.client_id,
          client_secret: env.VUE_APP_OPENID_SECRET_CLIENT,
          grant_type: 'authorization_code',
          state: savedState,
          code: code,
          code_verifier: clearCode,
          redirect_uri: config.params.redirect_uri,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) throw Error(response.statusText)
        return response.data
      })
      .then((tokens) => {
        localStorage.setItem('id_token', tokens.id_token)
        localStorage.setItem('access_token', tokens.access_token)
        localStorage.setItem('refresh_token', tokens.refresh_token)
      })
  }

  static async Logout(provider: Provider, config: OAuthConfig) : Promise<void>
  {
    const id: any = localStorage.getItem('id_token')

    sendFormLogoutOpenId(config, id as string)
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  }
}

export default OAuthClient

export { Provider, OAuthConfig }
