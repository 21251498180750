import { env } from '@/env'
import { AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import OAuthClient, { OAuthConfig, Provider } from '../oauth-client'

const tripalioConfig: OAuthConfig = {
  authorizePoint: `${env.VUE_APP_API_URL_CONNECT}connect/authorize`,
  tokenPoint: `${env.VUE_APP_API_URL_CONNECT}connect/token`,
  logoutPoint: `${env.VUE_APP_API_URL_CONNECT}connect/endsession`,
  logoutRedirect: `${env.VUE_APP_ADMIN_URL}#/`,
  params: {
    client_id: 'a495b318-f12a-11eb-9a03-0242ac130003',
    redirect_uri: `${env.VUE_APP_ADMIN_URL}#/callback`,
    include_granted_scopes: true,
    access_type: 'offline',
    response_type: 'code',
    scope: 'openid offline_access user.manage roles branchpro.data.manage payment.manage'
  }
}

export default class AuthApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async login(identifiant: string, password: string): Promise<AxiosResponse<any>> {
    return this.instance.post(
      '/connect/token',
      qs.stringify({
        grant_type: 'password',
        username: identifiant,
        password: password,
        client_id: 'a495b318-f12a-11eb-9a03-0242ac130003',
        client_secret: env.VUE_APP_OPENID_SECRET_CLIENT,
        scope: 'openid offline_access user.manage roles',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  async authorize() : Promise<void> {
    await OAuthClient.Authorize(tripalioConfig, Provider.TRIPALIO)
  }

  async token(code: string, state: string) : Promise<void> {
    await OAuthClient.GetToken(code, state, Provider.TRIPALIO, tripalioConfig)

  }

  async logout() : Promise<void> {
    await OAuthClient.Logout(Provider.TRIPALIO, tripalioConfig)
  }

  async refresh(refresh_token: string): Promise<AxiosResponse<any>> {
    return this.instance.post(
      '/connect/token',
      qs.stringify({
        refresh_token: refresh_token,
        client_id: 'a495b318-f12a-11eb-9a03-0242ac130003',
        client_secret: env.VUE_APP_OPENID_SECRET_CLIENT,
        grant_type: 'refresh_token',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }
}
