import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { ccnPreview } from './ccn.module.api'
import { ciblePreview } from './cible.module.api'
import { retraitePrestationWithCategory } from './retraitePrestation.module.api'
import { unitePreview } from './unite.module.api'

// Api interface
export default class RetraiteAccordApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<retraiteAccordWithSubPreview>> {
    return this.instance.get(`/RetraiteAccord/${id}`)
  }

  async search(search: retraiteAccordSearch): Promise<ApiResult<Pagging<retraiteAccordWithSubPreview>>> {
    return this.instance.post('/RetraiteAccord/search', search)
  }

  async create(newRetraiteAccord: retraiteAccordCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteAccord', newRetraiteAccord)
  }

  async update(retraiteAccord: retraiteAccordUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/RetraiteAccord', retraiteAccord)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/RetraiteAccord', { params: { id } })
  }

  async init(data: retraiteAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteAccord/all/init', data)
  }

  async duplicate(data: retraiteAccordDuplicate): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteAccord/all/duplicate', data)
  }

  async deleteAll(data: retraiteAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteAccord/all/delete', data)
  }

  async changeAll(data: retraiteAccordChange): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteAccord/all/change', data)
  }
}

// Types
type retraiteAccordSearch = {
  ccnId?: number
  cibleId?: number
  prestationId?: number
  isActive?: boolean
  page: number
  limit: number
}

type retraiteAccordCreate = {
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
}

type retraiteAccordUpdate = {
  id: number
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
  date?: string
}

type retraiteAccord = {
  accordId: number // id
  ccnId: number
  cibleId: number
  id: number // prestationId
  prestationId?: number
  valeur: string
  valeurUniteId: number
  plafond: string
  plafondUniteId: number
  commentaire: string
  isActive: boolean
  updatedAt: string
}

type retraiteAccordWithSubPreview = {
  id: number
  ccnId: number
  valeur: string
  commentaire: string
  isActive: boolean
  ccn: ccnPreview
  cible: ciblePreview
  prestation: retraitePrestationWithCategory
  valeurUnite: unitePreview
}

type retraiteAccordMergeWithPrestation = {
  accordId: number
  commentaire: string
  id: number
  isActive: false
  name: string
  order: number
  valeur: string
  valeurUniteId: number
  ValeurUniteName: string
}

type retraiteAccordInit = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
}

type retraiteAccordDuplicate = {
  srcCcnId: number
  srcCibleId: number
  destCcnId: number
  destCibleId: number
  nomenclatureId: number
}

type retraiteAccordChange = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
  destCibleId: number
}

export {
  retraiteAccordSearch,
  retraiteAccordCreate,
  retraiteAccordUpdate,
  retraiteAccordWithSubPreview,
  retraiteAccord,
  retraiteAccordInit,
  retraiteAccordDuplicate,
  retraiteAccordMergeWithPrestation,
  retraiteAccordChange
}
