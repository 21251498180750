<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-content>
      <v-img
        :src="require('@/assets/tripalio-logo.png')"
        min-width="120"
        height="44"
        contain
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'DefaultDrawerHeader',
})
</script>

<style scoped>
.v-list-item__content {
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 3px;
}
</style>