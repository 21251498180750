import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'

// properties
const state = {
  alert: {
    showing: false,
    type: 'success',
    message: '',
  },
  login: {
    refresh: null,
    token: null,
    expire: 0,
  },
  mini: false,
  drawer: true,
  drawerGradient: 1,
  drawerItems: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Utilisateur',
      expands: true,
      icon: 'mdi-account',
      items: [
        {
          title: 'Liste',
          icon: 'mdi-account-cog',
          to: '/users',
        },
        {
          title: 'Achats',
          icon: 'mdi-cash-multiple',
          to: '/achats',
        },
        {
          title: 'Entreprises',
          icon: 'mdi-domain',
          to: '/Entreprises',
        },
      ],
    },
    {
      title: 'Data',
      expands: true,
      icon: 'mdi-database',
      items: [
        {
          title: 'Conventions',
          icon: 'mdi-file-document-multiple-outline',
          to: '/ccns/',
          sep: true,
        },
        {
          title: 'Code APE',
          icon: 'mdi-numeric-1-box-multiple-outline',
          to: '/apes/',
        },
        {
          title: 'Accords santé',
          icon: 'mdi-hospital-box-outline',
          expands: false,
          items: [
            {
              title: 'Nomenclature santé',
              icon: 'mdi-file-tree',
              to: '/data/nomenclature/sante',
            },
            {
              title: 'Cibles',
              icon: 'mdi-account',
              to: '/data/cibles/sante',
            },
            {
              title: 'Unités',
              icon: 'mdi-percent',
              to: '/data/unites/sante',
            },
          ],
        },
        {
          title: 'Accords prevoyance',
          icon: 'mdi-wheelchair-accessibility',
          expands: false,
          items: [
            {
              title: 'Nomenclature prévoyance',
              icon: 'mdi-file-tree',
              to: '/data/nomenclature/prevoyance',
            },
            {
              title: 'Cibles',
              icon: 'mdi-account',
              to: '/data/cibles/prevoyance',
            },
            {
              title: 'Unités',
              icon: 'mdi-percent',
              to: '/data/unites/prevoyance',
            },
          ],
        },
        {
          title: 'Accords retraite',
          icon: 'mdi-hospital-box-outline',
          expands: false,
          items: [
            {
              title: 'Nomenclature retraite',
              icon: 'mdi-file-tree',
              to: '/data/nomenclature/retraite',
            },
            {
              title: 'Cibles',
              icon: 'mdi-account',
              to: '/data/cibles/retraite',
            },
            {
              title: 'Unités',
              icon: 'mdi-percent',
              to: '/data/unites/retraite',
            },
          ],
        },
        {
          title: 'Accords salaire',
          icon: 'mdi-hospital-box-outline',
          expands: false,
          items: [
            {
              title: 'Nomenclature salaire',
              icon: 'mdi-file-tree',
              to: '/data/nomenclature/salaire',
            },
            {
              title: 'Cibles',
              icon: 'mdi-account',
              to: '/data/cibles/salaire',
            },
            {
              title: 'Unités',
              icon: 'mdi-percent',
              to: '/data/unites/salaire',
            },
          ],
        },
        {
          title: 'Export Covéa',
          icon: 'mdi-export-variant',
          to: '/data/covea-export',
        },
        {
          title: 'Mise a jour partenaire sociaux',
          icon: 'mdi-account-reactivate-outline',
          to: '/data/updatePartenaire',
        },
      ],
    },
    {
      title: 'Développeur',
      icon: 'mdi-wheelchair-accessibility',
      expands: false,
      items: [
        {
          title: 'Rôles',
          icon: 'mdi-badge-account-horizontal-outline',
          to: '/roles',
        },
        {
          title: 'Abonnements',
          icon: 'mdi-currency-usd',
          to: '/abonnements',
        },
      ],
    },
    {
      title: 'Boutique',
      icon: 'mdi-store',
      to: '/data/boutique/',
    },
  ],
}

const getters = {}

const actions = {}

const mutations = make.mutations(state)

// store
Vue.use(Vuex)
export default new Vuex.Store({
  // use the plugin
  plugins: [pathify.plugin],

  // properties
  state,
  getters,
  actions,
  mutations,
})
