const _env_: { [key: string]: string | undefined } = (globalThis.window as any)?._env_ || {}

function getEnvVar(key: string) {
  return process.env[key] || _env_[key] || ''
}

export const env = {
  VUE_APP_API_URL: getEnvVar('VUE_APP_API_URL'),
  VUE_APP_API_URL_USER: getEnvVar('VUE_APP_API_URL_USER'),
  VUE_APP_API_URL_CONNECT: getEnvVar('VUE_APP_API_URL_CONNECT'),
  VUE_APP_API_URL_PAYMENT: getEnvVar('VUE_APP_API_URL_PAYMENT'),
  VUE_APP_ADMIN_URL: getEnvVar('VUE_APP_ADMIN_URL'),
  VUE_APP_TRIPALIO_APP_URL: getEnvVar('VUE_APP_TRIPALIO_APP_URL'),
  VUE_APP_OPENID_SECRET_CLIENT: getEnvVar('VUE_APP_OPENID_SECRET_CLIENT'),
}
