import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

export default class NewsApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<news>> {
    return this.instance.get(`/NewsCcn/${id}`)
  }

  async search(search: newsSearch): Promise<ApiResult<Pagging<news>>> {
    return this.instance.post(`/NewsCcn/search`, search)
  }

  async post(newNews: newsCreate): Promise<ApiResult<idItem>> {
    return this.instance.post(`/NewsCcn`, newNews)
  }

  async update(newNews: newsUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put(`/NewsCcn`, newNews)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete(`/NewsCcn`, { params: { id } })
  }
}

type newsCreate = {
  lien: string
  name: string
  creationDate: string
}

type newsSearch = {
  ccnId: number
  page: number
  limit: number
}

type getNews = {
  id: number
}

type newsUpdate = {
  id: number
  lien: string
  name: string
  ccnId: number
  creationDate: string
}

type deleteNews = {
  id: number
}

type news = {
  id: number
  ccnId: number
  lien: string
  name: string
  creationDate: string
  ccn: any
}

export{
  newsCreate, newsSearch, getNews, newsUpdate, deleteNews, news
}