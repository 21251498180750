import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { ccnPreview } from './ccn.module.api'
import { ciblePreview } from './cible.module.api'
import { prevoyancePrestationWithCategory } from './prevoyancePrestation.module.api'
import { unitePreview } from './unite.module.api'

// Api interface
export default class PrevoyanceAccordApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<prevoyanceAccordWithSubPreviewValeur>> {
    return this.instance.get(`/PrevoyanceAccord/${id}`)
  }

  async search(search: prevoyanceAccordSearch): Promise<ApiResult<Pagging<prevoyanceAccordWithSubPreview>>> {
    return this.instance.post('/PrevoyanceAccord/search', search)
  }

  async create(newPrevoyanceAccord: prevoyanceAccordCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord', newPrevoyanceAccord)
  }

  async createValeur(newPrevoyanceAccordValeur: prevoyanceAccordValueShort): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccordValeur', newPrevoyanceAccordValeur)
  }

  async update(prevoyanceAccord: prevoyanceAccordUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/PrevoyanceAccord', prevoyanceAccord)
  }

  async updateValeur(prevoyanceAccordValeur: prevoyanceAccordValueShort): Promise<ApiResult<idItem>> {
    return this.instance.put('/PrevoyanceAccordValeur', prevoyanceAccordValeur)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/PrevoyanceAccord', { params: { id } })
  }

  async deleteValeur(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/PrevoyanceAccordValeur', { params: { id } })
  }

  async init(data: prevoyanceAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord/init', data)
  }

  async duplicate(data: prevoyanceAccordDuplicate): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord/duplicate', data)
  }

  async duplicateOne(data: prevoyanceAccordDuplicateOne): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord/one/duplicate', data)
  }

  async deleteAll(data: prevoyanceAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord/all/delete', data)
  }

  async changeAll(data: prevoyanceAccordChange): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceAccord/all/change', data)
  }
}

// Types
type prevoyanceAccordDuplicateOne = {
  prestationId: number
  accordId: number
}

type prevoyanceAccordSearch = {
  ccnId?: number
  cibleId?: number
  prestationId?: number
  isActive?: boolean
  page: number
  limit: number
}

type prevoyanceAccordCreate = {
  ccnId: number
  cibleId: number
  text: string
  prestationId: number
  commentaire: string
  isActive: boolean
}

type prevoyanceAccordUpdate = {
  id: number
  ccnId: number
  cibleId: number
  prestationId: number
  text: string
  commentaire: string
  importantCommentaire: boolean
  isActive: boolean
  date: string
  prevoyanceAccordValeur?: prevoyanceAccordValueShort[]
}

type prevoyanceAccord = {
  id: number
  text?: string
  commentaire?: string
  importantCommentaire: boolean
  isActive: boolean
  values: prevoyanceAccordValue[]
}

type prevoyanceAccordWithSubPreview = {
  id: number
  valeur: string
  plafond: string
  commentaire: string
  isActive: boolean
  ccn: ccnPreview
  cible: ciblePreview
  plafondUnite: unitePreview
  prestation: prevoyancePrestationWithCategory
  valeurUnite: unitePreview
}

type prevoyanceAccordChange = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
  destCibleId: number
}

type prevoyanceAccordWithSubPreviewValeur = {
  id: number
  ccnId: number
  text: string
  commentaire: string
  importantCommentaire: true
  isActive: true
  cible: ciblePreview
  prestation: prevoyancePrestationWithCategory
  ccn: ccnPreview
  prevoyanceAccordValeur: prevoyanceAccordValueBase[]
}

type prevoyanceAccordValue = {
  id?: number
  accordId: number
  valeur: string
  unite: unitePreview
}

type prevoyanceAccordValueShort = {
  id?: number
  accordId: number
  valeur: string
  valeurUniteId: number
}

type prevoyanceAccordValueBase = {
  id?: number
  accordId: number
  valeur: string
  valeurUnite: unitePreview
}

type prevoyanceAccordInit = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
}

type prevoyanceAccordDuplicate = {
  srcCcnId: number
  srcCibleId: number
  destCcnId: number
  destCibleId: number
  nomenclatureId: number
}

export {
  prevoyanceAccordSearch,
  prevoyanceAccordCreate,
  prevoyanceAccordUpdate,
  prevoyanceAccordWithSubPreview,
  prevoyanceAccord,
  prevoyanceAccordValue,
  prevoyanceAccordValueShort,
  prevoyanceAccordInit,
  prevoyanceAccordDuplicate,
  prevoyanceAccordWithSubPreviewValeur,
  prevoyanceAccordChange,
}
