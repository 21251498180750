import router from '@/router'
import axios from 'axios'
import { apiServer } from '@/api/server.api'

// for multiple requests
let isRefreshing = false
let failedQueue = [] as any[]

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (error: any): any {
  const originalRequest = error.config

  if (error.response.status === 404) {
    return router.push('/unauthorized')
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          return axios(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }

    originalRequest._retry = true
    isRefreshing = true

    const refreshToken = window.localStorage.getItem('refresh_token')
    if (!refreshToken) return router.push('/login')

    return new Promise(function (resolve, reject) {
      apiServer.auth
        .refresh(refreshToken)
        .then(({ data }) => {
          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token

          originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token
          processQueue(null, data.token)
          resolve(axios(originalRequest))
        })
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.config.url == '/connect/token' &&
            err.response.data.error == 'invalid_grant'
          ) {
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('access_token')
            return router.push('/login')
          }

          processQueue(err, null)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }
  return Promise.reject(error)
}
