import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { abonnementSateEnum } from './abonnement.module.api'

// Api interface
export default class CompanyApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: string): Promise<ApiResult<companyBaseWithAbonnement>> {
    return this.instance.get(`/Company/${id}`)
  }

  async linkAbonnement(companyAbonnment: linkAbonnement): Promise<ApiResult<idItem>> {
    return this.instance.post('/Company/Abonnement', companyAbonnment)
  }

  async removeAbonnement(companyAbonnment: linkAbonnement): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Company/Abonnement', { data: companyAbonnment })
  }

  async create(newCompany: companyCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Company/', newCompany)
  }

  async update(abonnement: companyUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Company/', abonnement)
  }

  async search(search: companySearch): Promise<ApiResult<Pagging<companyPreview>>> {
    return this.instance.post('/Company/search', search)
  }

  async delete(id: string): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Company', { params: { id } })
  }

  async importUser(arg: companySyncUser): Promise<ApiResult<companySyncUserResponse>> {
    return this.instance.post('/Company/sync/users', arg)
  }
}

// Types
type company = {
  id: string
  name: string
  companyParentId?: string
}
type companyPreview = {
  id: string
  name: string
}
type companyCreate = {
  name: string
}
type companyUpdate = {
  id: string
  name: string
  companyParentId?: string
}
type companySearch = {
  sortBy?: any
  orderByDes?: boolean
  page: number
  limit: number
  name?: string
  abonnementState?: abonnementSateEnum
}
type companySyncUser = {
  id: string
  users: { userName: string; email: string; firstName: string; lastName: string }[]
  tmpPassword: string
}
type companySyncUserResponse = {
  success: string[]
  list: { in: string; err: string }[]
}
type companyAbonnement = {
  id: string
  abonnementId: string
  start: string
  end: string
}
type companyBaseWithAbonnement = {
  id: string
  name: string
  abonnements: companyAbonnement[]
}
type linkAbonnement = {
  id: string
  abonnementId: string
  start?: string
  end?: string
}

export { company, companyPreview, linkAbonnement, companyCreate, companyUpdate, companySearch, companySyncUser }
