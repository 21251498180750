import { AxiosInstance } from 'axios'
import { ApiResult, idItem } from '../server.api'

// Api interface
export default class SanteCategoryApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(newSanteCategory: santeCategorySearch): Promise<ApiResult<any>> {
    return this.instance.post('/SanteCategory/search', newSanteCategory)
  }

  async create(newSanteCategory: santeCategoryCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteCategory', newSanteCategory)
  }

  async modify(santeCategory: santeCategoryUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SanteCategory', santeCategory)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SanteCategory', { params: { id } })
  }
}

// Types
type santeCategoryCreate = {
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type santeCategoryUpdate = {
  id: number
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
  commentaire: string
}

type santeCategorySearch = {
  nomenclatureId?: number
  page: number
  limit: number
}

type santeCategoryPreview = {
  id: number
  name: string
  order: number
}

type deleteSanteCategory = {
  id: number
}

type santeCategory = santeCategoryUpdate

export {
  santeCategoryCreate,
  santeCategoryUpdate,
  santeCategory,
  santeCategorySearch,
  deleteSanteCategory,
  santeCategoryPreview,
}
