import { AxiosInstance } from 'axios'
import { idItem, ApiResult, Pagging } from '../server.api'
import { assocApeCcn } from './assocApeCcn.module.api'
import { ccnPreview } from './ccn.module.api'

// Api interface
export default class ApeModuleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<ape>> {
    return this.instance.get(`/Ape/${id}`)
  }

  async getWithCcn(id: number): Promise<ApiResult<apeWithCcnPreview>> {
    return this.instance.get(`/Ape/${id}/ccns`)
  }

  async search(search: apeSearch): Promise<ApiResult<Pagging<ape>>> {
    return this.instance.post('/Ape/search', search)
  }

  async searchWithCcn(search: apeSearch): Promise<ApiResult<Pagging<apeWithCcnPreview>>> {
    return this.instance.post('/Ape/search/ccns', search)
  }

  async create(newApe: apeCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Ape', newApe)
  }

  async update(newApe: apeUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Ape', newApe)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Ape', { params: { id } })
  }
}

// Types
/*
 * Input
 */
type apeCreate = {
  code: string
  name: string
  ccns: assocApeCcn[] | number[]
}

type apeSearch = {
  allCcnMatch?: boolean
  name?: string
  code?: string
  ccnIds?: number[]
  page: number
  limit: number
  orderByDes?: boolean
  sortBy?: string
  reverseCcnIds?: boolean
}

type apeUpdate = {
  id: number
  code: string
  name: string
  ccns: number[]
}

/*
 * Output
 */
type ape = {
  id: number
  code: string
  name: string
}

type apeWithCcnPreview = {
  id: number
  code: string
  name: string
  ccn: ccnPreview[]
}

export { ape, apeWithCcnPreview, apeCreate, apeSearch, apeUpdate }
