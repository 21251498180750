
import Vue from 'vue'

import '@/styles/overrides.sass'
const default_layout = 'default'

export default Vue.extend({
  name: 'App',

  data: () => ({}),

  computed: {
    layout() {
      return (this?.$route?.meta?.layout || default_layout) + '-layout'
    },
  },
})
