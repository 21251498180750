<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="true"
    :right="$vuetify.rtl"
    :mini-variant.sync="mini"
    :permanent="permanant"
    mini-variant-width="80"
    app
    width="260"
  >
    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="drawerItems" />
    </div>

    <template #img="props">
      <v-img :gradient="'rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)'" v-bind="props" />
    </template>

    <template #append>
      <div class="px-2">
        <default-list :items="items" />
      </div>
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import { get, sync } from 'vuex-pathify'

import DefaultList from '../shared/List'
import DefaultDrawerHeader from './DrawerHeader'

export default Vue.extend({
  name: 'DefaultDrawer',

  data: () => ({
    items: [
    ],
  }),

  components: {
    DefaultList,
    DefaultDrawerHeader,
  },

  computed: {
    ...get(['mini', 'drawerItems']),
    ...sync(['drawer']),
    permanant() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return false
      }
      return true
    },
  },
})
</script>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.v-navigation-drawer__content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item__icon
    padding-left: 4px

  .v-list-item__title
    font-size: 1em

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 0px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
