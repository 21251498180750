import { AxiosInstance } from 'axios'
import { ApiResult } from '../server.api'

// Api interface
export default class ExterneApiApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async getCcn(): Promise<ApiResult<any>> {
    return this.instance.get(`/api/covea/ccn`)
  }
  async getApe(): Promise<ApiResult<any>> {
    return this.instance.get(`/api/covea/ape`)
  }
}