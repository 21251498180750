import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api interface
export default class CibleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async getSante(search: cibleSearch): Promise<ApiResult<Pagging<NomenclaturCibleLink>>> {
    return this.instance.post(`/Cibles/sante_search`, search)
  }

  async getPrevoyance(search: cibleSearch): Promise<ApiResult<Pagging<NomenclaturCibleLink>>> {
    return this.instance.post(`/Cibles/prevoyance_search`, search)
  }

  async getRetraite(search: cibleSearch): Promise<ApiResult<Pagging<NomenclaturCibleLink>>> {
    return this.instance.post(`/Cibles/retraite_search`, search)
  }

  async search(search: cibleSearch): Promise<ApiResult<Pagging<cible>>> {
    return this.instance.post('/Cibles/search', search)
  }

  async get(id: number): Promise<ApiResult<cible>> {
    return this.instance.get(`/Cibles/${id}`)
  }

  async getLinkSante(search: cibleSanteSearch): Promise<ApiResult<Pagging<cible>>> {
    return this.instance.post('/Cibles/link_sante', search)
  }

  async getLinkPrevoyance(search: ciblePrevoyanceSearch): Promise<ApiResult<Pagging<cible>>> {
    return this.instance.post('/Cibles/link_prevoyance', search)
  }

  async getLinkRetraite(search: cibleRetraiteSearch): Promise<ApiResult<Pagging<cible>>> {
    return this.instance.post('/Cibles/link_retraite', search)
  }

  async create(newCible: cibleCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Cibles', newCible)
  }

  async update(cible: cibleUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Cibles', cible)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Cibles', { params: { id } })
  }
}

enum CibleTypeEnum {
  Sante,
  Prevoyance,
  Retraite,
  Salaire
}

export type CibleEnumAsKey = keyof typeof CibleTypeEnum

// Types
/*
 * Input
 */
type cibleCreate = {
  name: string
  type: CibleEnumAsKey
}

type cibleUpdate = {
  id: number
  name: string
  type: CibleEnumAsKey
}

type cibleSearch = {
  orderByDes?: boolean
  sortBy?: 'Id' | 'Name' | 'Type'
  name?: string
  type?: CibleEnumAsKey
  id?: number
  page: number
  limit: number
}

type cibleSanteSearch = {
  orderByDes?: boolean
  // sortBy?: 'Id' | 'Name' | 'Type'
  id: number
  page: number
  limit: number
}

type ciblePrevoyanceSearch = {
  orderByDes?: boolean
  // sortBy?: 'Id' | 'Name' | 'Type'
  id: number
  page: number
  limit: number
}

type cibleRetraiteSearch = {
  orderByDes?: boolean
  // sortBy?: 'Id' | 'Name' | 'Type'
  id: number
  page: number
  limit: number
}

/*
 * Output
 */
type cible = {
  id: number
  name: string
  type: CibleEnumAsKey
}

type NomenclaturCibleLink = {
  nomenclatureId: number
  nomenclatureName: string
  ccnId: number
  cibleId: number
  cibleName: string
}

type ciblePreview = {
  id: number
  name: string
}

export {
  ciblePrevoyanceSearch,
  cibleSanteSearch,
  cibleRetraiteSearch,
  CibleTypeEnum,
  cible,
  ciblePreview,
  cibleCreate,
  cibleUpdate,
  cibleSearch,
}
