<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
        v-bind:style="[mini ? { 'background-color': 'rgba(0,0,0,0.6)', 'border-radius': '5px' } : {}]"
      />

      <default-list-item v-else :key="`item-${i}`" :item="item" />
    </template>
  </v-list>
</template>

<script>
import Vue from 'vue'
import { get } from 'vuex-pathify'

export default Vue.extend({
  name: 'DefaultList',

  components: {
    DefaultListGroup: () => import('./ListGroup'),
    DefaultListItem: () => import('./ListItem'),
  },

  computed: {
    mini: get('mini')
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
