import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { ape } from './ape.module.api'

// Api interface
export default class CcnModuleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<ccn>> {
    return this.instance.get(`/Ccn/${id}`)
  }

  async getWithApe(id: number): Promise<ApiResult<ccnWithApePreview>> {
    return this.instance.get(`/Ccn/${id}/apes`)
  }

  async getCcnReplacement(search: ccnReplaceSearch): Promise<ApiResult<Pagging<ccnReplacement>>> {
    return this.instance.post(`/CcnReplace/Search`, search)
  }

  async linkCcnReplacement(link: ccnReplaceLink): Promise<ApiResult<ccn>> {
    return this.instance.post('/CcnReplace', link)
  }

  async unlinkCcnReplacement(unlink: ccnReplaceLink): Promise<ApiResult<ccn>> {
    return this.instance.delete('/CcnReplace', {
      data: {
        newCcnId: unlink.newCcnId,
        oldCcnId: unlink.oldCcnId,
      },
    })
  }

  async search(search: ccnSearch): Promise<ApiResult<Pagging<ccn>>> {
    return this.instance.post('/Ccn/search', search)
  }

  async searchWithApe(search: ccnSearch): Promise<ApiResult<Pagging<ccn>>> {
    return this.instance.post('/Ccn/search/apes', search)
  }

  async create(newCcn: ccnCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Ccn', newCcn)
  }

  async update(newCcn: ccnUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Ccn', newCcn)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Ccn', { params: { id } })
  }
}

// Types
/*
 * Input
 */
type ccnCreate = {
  code: string
  name: string
  fullName: string
  brochureNum?: number
  legifranceUrl?: string
  pdfUrl?: string
  champTerritorial?: string
  apes: number[]
}

type ccnUpdate = {
  id: number
  code: string
  name: string
  fullName: string
  brochureNum?: number
  legifranceUrl?: string
  pdfUrl?: string
  champTerritorial?: string
  apes: number[]
}

type ccnSearch = {
  allApeMatch?: boolean
  name?: string
  fullName?: string
  code?: string
  apeIds?: number[]
  page: number
  limit: number
  orderByDes?: boolean
  sortBy?: string
  reverseApeIds?: boolean
  nameOrCode?: boolean
}

type ccnReplaceSearch = {
  oldCcnId: number
  page: number
  limit: number
}

type ccnReplaceLink = {
  oldCcnId: number
  newCcnId: number
  commentaire?: string
}

/*
 * Output
 */
type ccn = {
  id: number
  name: string
  code: string
  fullName: string
  brochureNum?: number
  legifranceUrl?: string
  pdfUrl?: string
  champTerritorial?: string
}

type ccnPreview = {
  id: number
  code: string
  name: string
}

type ccnWithApePreview = {
  id: number
  name: string
  code: string
  fullName: string
  brochureNum?: number
  legifranceUrl?: string
  pdfUrl?: string
  champTerritorial?: string
  apes: ape[]
}

type ccnReplacement = {
  oldCcnId: number
  newCcnId: number
  newCcn: ccn
  oldCcn: ccn
}

export {
  ccn,
  ccnPreview,
  ccnWithApePreview,
  ccnReplaceSearch,
  ccnReplacement,
  ccnReplaceLink,
  ccnSearch,
  ccnUpdate,
  ccnCreate,
}
