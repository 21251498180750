import { AxiosInstance } from 'axios'
import { ApiResult, idItem } from '../server.api'

// Api interface
export default class RetraiteCategoryApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(newRetraiteCategory: retraiteCategorySearch): Promise<ApiResult<any>> {
    return this.instance.post('/RetraiteCategory/search', newRetraiteCategory)
  }

  async create(newRetraiteCategory: retraiteCategoryCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteCategory', newRetraiteCategory)
  }

  async modify(retraiteCategory: retraiteCategoryUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/RetraiteCategory', retraiteCategory)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/RetraiteCategory', { params: { id } })
  }
}

// Types
type retraiteCategoryCreate = {
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type retraiteCategoryUpdate = {
  id: number
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type retraiteCategorySearch = {
  nomenclatureId?: number
  page: number
  limit: number
}

type retraiteCategoryPreview = {
  id: number
  name: string
  order: number
}

type deleteRetraiteCategory = {
  id: number
}

type retraiteCategory = retraiteCategoryUpdate

export {
  retraiteCategoryCreate,
  retraiteCategoryUpdate,
  retraiteCategory,
  retraiteCategorySearch,
  deleteRetraiteCategory,
  retraiteCategoryPreview,
}
