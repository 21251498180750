import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify/vuetify'
import DefaultLayout from './layouts/Default.vue'
import EmptyLayout from './layouts/Empty.vue'
import './plugins/vuetify/prototypes.js'

Vue.config.productionTip = false
Vue.component('default-layout', DefaultLayout)
Vue.component('empty-layout', EmptyLayout)

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
