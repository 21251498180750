import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { ccnPreview } from './ccn.module.api'
import { ciblePreview } from './cible.module.api'
import { salairePrestationWithCategory } from './salairePrestation.module.api'
import { unitePreview } from './unite.module.api'

// Api interface
export default class SalaireAccordApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<salaireAccordWithSubPreview>> {
    return this.instance.get(`/SalaireAccord/${id}`)
  }

  async search(search: salaireAccordSearch): Promise<ApiResult<Pagging<salaireAccordWithSubPreview>>> {
    return this.instance.post('/SalaireAccord/search', search)
  }

  async create(newSalaireAccord: salaireAccordCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord', newSalaireAccord)
  }

  async update(salaireAccord: salaireAccordUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SalaireAccord', salaireAccord)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SalaireAccord', { params: { id } })
  }

  async init(data: salaireAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord/all/init', data)
  }

  async duplicate(data: salaireAccordDuplicate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord/all/duplicate', data)
  }

  async duplicateOne(data: salaireAccordDuplicateOne): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord/one/duplicate', data)
  }

  async deleteAll(data: salaireAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord/all/delete', data)
  }

  async changeAll(data: salaireAccordChange): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireAccord/all/change', data)
  }
}

// Types
type salaireAccordSearch = {
  ccnId?: number
  cibleId?: number
  prestationId?: number
  isActive?: boolean
  page: number
  limit: number
}

type salaireAccordDuplicateOne = {
  prestationId: number
  accordId: number
}

type salaireAccordCreate = {
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
}

type salaireAccordUpdate = {
  id: number
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
  date?: string
}

type salaireAccord = {
  accordId: number // id
  ccnId: number
  cibleId: number
  id: number // prestationId
  prestationId?: number
  valeur: string
  valeurUniteId: number
  plafond: string
  plafondUniteId: number
  commentaire: string
  isActive: boolean
  updatedAt: string
}

type salaireAccordWithSubPreview = {
  id: number
  ccnId: number
  valeur: string
  commentaire: string
  isActive: boolean
  ccn: ccnPreview
  cible: ciblePreview
  prestation: salairePrestationWithCategory
  valeurUnite: unitePreview
}

type salaireAccordMergeWithPrestation = {
  accordId: number
  commentaire: string
  id: number
  isActive: false
  name: string
  order: number
  valeur: string
  valeurUniteId: number
  ValeurUniteName: string
}

type salaireAccordInit = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
}

type salaireAccordDuplicate = {
  srcCcnId: number
  srcCibleId: number
  destCcnId: number
  destCibleId: number
  nomenclatureId: number
}

type salaireAccordChange = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
  destCibleId: number
}

export {
  salaireAccordSearch,
  salaireAccordCreate,
  salaireAccordUpdate,
  salaireAccordWithSubPreview,
  salaireAccord,
  salaireAccordInit,
  salaireAccordDuplicate,
  salaireAccordMergeWithPrestation,
  salaireAccordChange,
}
