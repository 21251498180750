import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api interface
export default class PrevoyanceNomenclatureApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<prevoyanceNomenclature>> {
    return this.instance.get(`/PrevoyanceNomenclature/${id}`)
  }

  async getWithPrestation(id: number): Promise<ApiResult<prevoyanceNomenclatureWithPrestation>> {
    return this.instance.get(`/PrevoyanceNomenclature/${id}/prestation`)
  }

  async getByIdAndCibleIdAndCcnId(
    id: number,
    cibleId: number,
    ccnId: number
  ): Promise<ApiResult<prevoyanceNomenclatureWithPrestation>> {
    return this.instance.get(`/PrevoyanceNomenclature/${id}/prestation/accord/all/${cibleId}/${ccnId}`)
  }

  async getNomenclatureAndCible(
    search: prevoyanceNomenclatureSearch
  ): Promise<ApiResult<Pagging<prevoyanceNomenclatureAndCible>>> {
    return this.instance.post('/Cibles/prevoyance_search', search)
  }

  async search(search: prevoyanceNomenclatureSearch): Promise<ApiResult<Pagging<prevoyanceNomenclature>>> {
    return this.instance.post('/PrevoyanceNomenclature/search', search)
  }

  async create(newPrevoyanceNomenclature: prevoyanceNomenclatureCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceNomenclature', newPrevoyanceNomenclature)
  }

  async modify(prevoyanceNomenclature: prevoyanceNomenclatureUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/PrevoyanceNomenclature', prevoyanceNomenclature)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/PrevoyanceNomenclature', { params: { id } })
  }
}

// Types
type prevoyanceNomenclatureSearch = {
  page: number
  limit: number
  name?: string
  id?: number
}

type prevoyanceNomenclatureCreate = {
  name: string
}

type prevoyanceNomenclatureUpdate = {
  id: number
  name: string
}

/*
 * Output
 */
type prevoyanceNomenclature = {
  id: number
  name: string
}

type prevoyanceNomenclatureWithPrestation = {
  nomenclature: prevoyanceNomenclature
  prestations: any
}

type prevoyanceNomenclatureAndCible = {
  nomenclatureId: number
  nomenclatureName: string
  ccnId: number
  cibleId: number
  cibleName: string
}

export {
  prevoyanceNomenclatureWithPrestation,
  prevoyanceNomenclatureSearch,
  prevoyanceNomenclatureCreate,
  prevoyanceNomenclatureUpdate,
  prevoyanceNomenclature,
  prevoyanceNomenclatureAndCible,
}
