<template>
  <v-app>
    <default-bar />

    <default-drawer />

    <v-main>
      <router-view :key="$route.path" />
    </v-main>

    <alert></alert>

    <!-- <default-settings /> -->
  </v-app>
</template>

<script>
import Vue from 'vue'

// import DefaultSettings from './default/Settings'
import DefaultBar from './default/appbar/AppBar'
import DefaultDrawer from './default/drawer/Drawer'
import Alert from '@/components/misc/GlobalAlert.vue'

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    DefaultBar,
    DefaultDrawer,
    // DefaultSettings,
    Alert,
  },
})
</script>

<style lang="scss"></style>
