import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { prevoyanceAccord } from './prevoyanceAccord.module.api'
import { prevoyanceCategoryPreview } from './prevoyanceCategory.module.api'

// Api interface
export default class PrevoyancePrestationApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(config: searchPrevoyancePrestation): Promise<ApiResult<Pagging<prevoyancePrestationWithCategory>>> {
    return this.instance.post('/PrevoyancePrestation/search', config)
  }

  async create(newPrevoyancePrestation: prevoyancePrestationCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyancePrestation', newPrevoyancePrestation)
  }

  async modify(prevoyancePrestation: prevoyancePrestationUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/PrevoyancePrestation', prevoyancePrestation)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/PrevoyancePrestation', { params: { id } })
  }
}

// Types
type prevoyancePrestationSearch = {
  name?: string
  page: number
  limit: number
}

type prevoyancePrestationCreate = {
  categoryId: number
  name: string
  order: number
}

type prevoyancePrestationUpdate = {
  id: number
  categoryId: number
  name: string
  order: number
  hide?: boolean
}

type searchPrevoyancePrestation = {
  name?: string
}

/*
 * Output
 */

type prevoyancePrestation = {
  id: number
  accord: prevoyanceAccord
  name: string
  order: number
  hide?: boolean
}

type prevoyancePrestationWithCategory = {
  id: number
  name: string
  order: number
  category: prevoyanceCategoryPreview
}

type deletePrevoyancePrestation = {
  id: number
}

export {
  searchPrevoyancePrestation,
  prevoyancePrestation,
  prevoyancePrestationWithCategory,
  prevoyancePrestationSearch,
  prevoyancePrestationCreate,
  prevoyancePrestationUpdate,
  deletePrevoyancePrestation,
}
