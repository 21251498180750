<template>
  <v-app>
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'EmptyLayout',
})
</script>

<style lang="scss"></style>
