
import store from '@/store/store'
import Vue from 'vue'
import { get } from 'vuex-pathify'

export default Vue.extend({
  name: 'Alert',

  computed: {
    ...get(['alert']),
  },

  methods: {
    close() {
      store.commit('SET_ALERT', { showing: false })
    },
  },
})
