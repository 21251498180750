import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

export default class BoutiqueApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async getProducts(search: productSearch): Promise<ApiResult<Pagging<product>>> {
    return this.instance.post('/payment/Product/search', search)
  }

  async forceCreatePayment(create: createPayment): Promise<ApiResult<idItem>> {
    return this.instance.post('/payment/ForceCreate', create)
  }

  async deletePayment(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete(`/payment/${id}`)
  }

  async get(id: number): Promise<ApiResult<product>> {
    return this.instance.get(`/payment/Product/${id}`)
  }

  async create(newProduct: newProduct): Promise<ApiResult<idItem>> {
    return this.instance.post('/payment/Product', newProduct)
  }

  async createCategorie(createCategorie: createCategorie): Promise<ApiResult<idItem>> {
    return this.instance.post('/payment/ProductRole', createCategorie)
  }

  async getCategories(roleSearch: roleSearch): Promise<ApiResult<Pagging<roles>>> {
    return this.instance.post('/payment/ProductRole/search', roleSearch)
  }

  async update(product: updateProduct): Promise<ApiResult<idItem>> {
    return this.instance.put('/payment/Product', product)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete(`/payment/Product/${id}`)
  }

  async searchPayment(search: paymentSearch): Promise<ApiResult<Pagging<payment>>> {
    return this.instance.post('/payment/search', search)
  }

  async getTvaIds(): Promise<tvaResult[]> {
    return this.instance.get(`/payment/StripeTaxCodes`)
  }

  async addFreeAbonnementOfProduct(id: any, aboNames: string): Promise<any> {
    return this.instance.post(`/payment/Product/${id}/ProductSubscription`, aboNames)
  }

  async getFreeAbonnementOfProduct(id: any): Promise<any> {
    return this.instance.get(`/payment/Product/${id}/ProductSubscription`)
  }
}

type tvaResult = {
  id: string
  active: boolean
  country: string
  description: string
  displayName: string
  percentage: number
  taxType: string
}

type productSearch = {
  page: number
  limit: number
  title?: string
  productId?: string
  subTitle?: string
  category?: number
}

type paymentSearch = {
  page: number
  limit: number
  orderByDes?: boolean
  sortBy?: string
  itemId?: number
  userId?: string
  intentId?: string
  priceId?: string
}

type createPayment = {
  itemId: number
  intentId: string
  priceId: string
  userId: string
}

type payment = {
  id: number
  userId: string
  intentId: string
  purchaseAt: string
}

type createCategorie = {
  role: string
}

type roles = {
  page: number
  limit: number
  count: number
  total: number
  items: Array<any>
}

type roleSearch = {
  page: number
  limit: number
}

type newProduct = {
  title: string
  images: Array<string>
  price: number
  subTitle: string
  description: string
  moreInfo: string
  category: number
  file: string
  fileName: string
  unique: boolean
  nameImages: Array<string>
  listRoleRestrict: Array<number>
  taxCode: string
  order: number
}

type updateProduct = {
  id: number
  title: string
  images: Array<string>
  nameImages: Array<string>
  file: string
  fileName: string
  price: number
  subTitle: string
  description: string
  moreInfo: string
  roles?: Array<any>
  taxCode: string
  isActive: boolean
  category: number
  order: number
}

type deleteProduct = {
  id: number
}

type product = {
  id: number
  productId: string
  title: string
  imageUrls: string[]
  price: number
  subTitle: string
  description: string
  moreInfo: string
  oldPrice: number
  file: string
  category: number
  unique: boolean
  roles: Array<any>
  taxCode: string
  isActive: boolean
  productOrder: number
}

export {
  product,
  productSearch,
  newProduct,
  updateProduct,
  deleteProduct,
  roleSearch,
  roles,
  createCategorie,
  payment,
  paymentSearch,
  createPayment,
  tvaResult,
}
