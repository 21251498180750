import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api interface
export default class RoleModuleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(name: string): Promise<ApiResult<Pagging<role>>> {
    return this.instance.post('/Role/Search', { limit: 1, page: 1, name: name })
  }

  async create(newRole: roleCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Role/', newRole)
  }

  async update(abonnement: roleUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Role/', abonnement)
  }

  async search(search: roleSearch): Promise<ApiResult<Pagging<rolePreview>>> {
    return this.instance.post('/Role/Search', search)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Role', { params: { id } })
  }
}

// Types
type role = {
  id: number
  name: string
}
type rolePreview = {
  id: number
  name: string
}
type roleCreate = {
  name: string
}
type roleUpdate = {
  id: number
  name: string
}
type roleSearch = {
  orderByDescending?: boolean
  page: number
  limit: number
  name?: string
}

export { role, rolePreview, roleCreate, roleUpdate, roleSearch }
