import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { retraiteCategoryPreview } from './retraiteCategory.modue.api'

// Api interface
export default class RetraitePrestationApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(config: searchRetraitePrestation): Promise<ApiResult<Pagging<retraitePrestationWithCategory>>> {
    return this.instance.post('/RetraitePrestation/search', config)
  }

  async create(newRetraitePrestation: retraitePrestationCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraitePrestation', newRetraitePrestation)
  }

  async modify(retraitePrestation: retraitePrestationUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/RetraitePrestation', retraitePrestation)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/RetraitePrestation', { params: { id } })
  }
}

// Types
type retraitePrestationSearch = {
  name?: string
  page: number
  limit: number
}

type retraitePrestationCreate = {
  categoryId: number
  name: string
  order: number
}

type retraitePrestationUpdate = {
  id: number
  categoryId: number
  name: string
  order: number
  hide?: boolean
}

type searchRetraitePrestation = {
  name?: string
}

/*
 * Output
 */

type retraitePrestation = {
  id: number
  name: string
  order: number
  hide?: boolean
}

type retraitePrestationWithCategory = {
  id: number
  name: string
  order: number
  category: retraiteCategoryPreview
}

type deleteRetraitePrestation = {
  id: number
}

export {
  searchRetraitePrestation,
  retraitePrestation,
  retraitePrestationWithCategory,
  retraitePrestationSearch,
  retraitePrestationCreate,
  retraitePrestationUpdate,
  deleteRetraitePrestation,
}
