import { AxiosInstance } from 'axios'
import { ApiResult, idItem } from '../server.api'

// Api interface
export default class SalaireCategoryApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(newSalaireCategory: salaireCategorySearch): Promise<ApiResult<any>> {
    return this.instance.post('/SalaireCategory/search', newSalaireCategory)
  }

  async create(newSalaireCategory: salaireCategoryCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireCategory', newSalaireCategory)
  }

  async modify(salaireCategory: salaireCategoryUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SalaireCategory', salaireCategory)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SalaireCategory', { params: { id } })
  }
}

// Types
type salaireCategoryCreate = {
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type salaireCategoryUpdate = {
  id: number
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
  commentaire: string
}

type salaireCategorySearch = {
  nomenclatureId?: number
  page: number
  limit: number
}

type salaireCategoryPreview = {
  id: number
  name: string
  order: number
}

type deleteSalaireCategory = {
  id: number
}

type salaireCategory = salaireCategoryUpdate

export {
  salaireCategoryCreate,
  salaireCategoryUpdate,
  salaireCategory,
  salaireCategorySearch,
  deleteSalaireCategory,
  salaireCategoryPreview,
}
