import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { salairePrestationSearch } from './salairePrestation.module.api'

// Api interface
export default class SalaireNomenclatureApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<salaireNomenclature>> {
    return this.instance.get(`/SalaireNomenclature/${id}`)
  }

  async getWithPrestation(id: number): Promise<ApiResult<salaireNomenclatureWithPrestation>> {
    return this.instance.get(`/SalaireNomenclature/${id}/prestation`)
  }

  async getByIdAndCibleIdAndCcnId(
    id: number,
    cibleId: number,
    ccnId: number
  ): Promise<ApiResult<salaireNomenclatureWithPrestation>> {
    return this.instance.get(`/SalaireNomenclature/${id}/prestation/accord/all/${cibleId}/${ccnId}`)
  }

  async getNomenclatureAndCible(search: salairePrestationSearch): Promise<ApiResult<Pagging<salaireNomenclatureAndCible>>> {
    return this.instance.post('/Cibles/salaire_search', search)
  }

  async search(search: salaireNomenclatureSearch): Promise<ApiResult<Pagging<salaireNomenclature>>> {
    return this.instance.post('/SalaireNomenclature/search', search)
  }

  async create(newSalaireNomenclature: salaireNomenclatureCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalaireNomenclature', newSalaireNomenclature)
  }

  async modify(salaireNomenclature: salaireNomenclatureUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SalaireNomenclature', salaireNomenclature)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SalaireNomenclature', { params: { id } })
  }
}

// Types
type salaireNomenclatureSearch = {
  page: number
  limit: number
  name?: string
  id?: number
}

type salaireNomenclatureCreate = {
  name: string
}

type salaireNomenclatureUpdate = {
  id: number
  name: string
}

/*
 * Output
 */
type salaireNomenclature = {
  id: number
  name: string
}

type salaireNomenclatureWithPrestation = {
  nomenclature: salaireNomenclature
  prestations: any
}

type salaireNomenclatureAndCible = {
  nomenclatureId: number
  nomenclatureName: string
  ccnId: number
  cibleId: number
  cibleName: string
}

export {
  salaireNomenclatureWithPrestation,
  salaireNomenclatureSearch,
  salaireNomenclatureCreate,
  salaireNomenclatureUpdate,
  salaireNomenclature,
  salaireNomenclatureAndCible,
}
