import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { ccnPreview } from './ccn.module.api'
import { ciblePreview } from './cible.module.api'
import { santePrestationWithCategory } from './santePrestation.module.api'
import { unitePreview } from './unite.module.api'

// Api interface
export default class SanteAccordApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<santeAccordWithSubPreview>> {
    return this.instance.get(`/SanteAccord/${id}`)
  }

  async search(search: santeAccordSearch): Promise<ApiResult<Pagging<santeAccordWithSubPreview>>> {
    return this.instance.post('/SanteAccord/search', search)
  }

  async create(newSanteAccord: santeAccordCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord', newSanteAccord)
  }

  async update(santeAccord: santeAccordUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SanteAccord', santeAccord)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SanteAccord', { params: { id } })
  }

  async init(data: santeAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord/all/init', data)
  }

  async duplicate(data: santeAccordDuplicate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord/all/duplicate', data)
  }

  async duplicateOne(data: santeAccordDuplicateOne): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord/one/duplicate', data)
  }

  async deleteAll(data: santeAccordInit): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord/all/delete', data)
  }

  async changeAll(data: santeAccordChange): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteAccord/all/change', data)
  }
}

// Types
type santeAccordSearch = {
  ccnId?: number
  cibleId?: number
  prestationId?: number
  isActive?: boolean
  page: number
  limit: number
}

type santeAccordDuplicateOne = {
  prestationId: number
  accordId: number
}

type santeAccordCreate = {
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
}

type santeAccordUpdate = {
  id: number
  ccnId: number
  cibleId: number
  prestationId: number
  valeur: string
  valeurUniteId: number
  commentaire: string
  isActive: boolean
  date?: string
  forceRss?: number
}

type santeAccord = {
  accordId: number // id
  ccnId: number
  cibleId: number
  id: number // prestationId
  prestationId?: number
  valeur: string
  valeurUniteId: number
  plafond: string
  plafondUniteId: number
  commentaire: string
  isActive: boolean
  updatedAt: string
}

type santeAccordWithSubPreview = {
  id: number
  ccnId: number
  valeur: string
  commentaire: string
  isActive: boolean
  ccn: ccnPreview
  cible: ciblePreview
  prestation: santePrestationWithCategory
  valeurUnite: unitePreview
  forceRss: number
}

type santeAccordMergeWithPrestation = {
  accordId: number
  commentaire: string
  id: number
  isActive: false
  name: string
  order: number
  valeur: string
  valeurUniteId: number
  ValeurUniteName: string
}

type santeAccordInit = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
}

type santeAccordDuplicate = {
  srcCcnId: number
  srcCibleId: number
  destCcnId: number
  destCibleId: number
  nomenclatureId: number
}

type santeAccordChange = {
  ccnId: number
  cibleId: number
  nomenclatureId: number
  destCibleId: number
}

export {
  santeAccordSearch,
  santeAccordCreate,
  santeAccordUpdate,
  santeAccordWithSubPreview,
  santeAccord,
  santeAccordInit,
  santeAccordDuplicate,
  santeAccordMergeWithPrestation,
  santeAccordChange,
}
