import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account")])],1)]}}])},[_c(VList,{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c(VDivider,{key:`divider-${i}`,staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:`item-${i}`,attrs:{"to":p.dest}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(p.title)}})],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }