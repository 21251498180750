import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{staticClass:"v-bar--underline",attrs:{"id":"default-app-bar","app":"","absolute":"","clipped-left":_vm.$vuetify.rtl,"clipped-right":!_vm.$vuetify.rtl,"height":"60","flat":"","dark":true}},[_c(VAppBarNavIcon,{staticClass:"hidden-md-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('default-drawer-toggle',{staticClass:"hidden-sm-and-down"}),_c(VToolbarTitle,{staticClass:"font-weight-light text-h5",domProps:{"textContent":_vm._s(this.$route.name)}}),_c(VSpacer),_c(VBtn,{on:{"click":_vm.redirectToApp}},[_vm._v("Revenir sur Tripalio")]),_c('default-account')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }