import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { santeCategoryPreview } from './santeCategory.modue.api'

// Api interface
export default class SantePrestationApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(config: searchSantePrestation): Promise<ApiResult<Pagging<santePrestationWithCategory>>> {
    return this.instance.post('/SantePrestation/search', config)
  }

  async create(newSantePrestation: santePrestationCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SantePrestation', newSantePrestation)
  }

  async modify(santePrestation: santePrestationUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SantePrestation', santePrestation)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SantePrestation', { params: { id } })
  }
}

// Types
type santePrestationSearch = {
  name?: string
  page: number
  limit: number
}

type santePrestationCreate = {
  categoryId: number
  name: string
  order: number
}

type santePrestationUpdate = {
  id: number
  categoryId: number
  name: string
  order: number
  hide?: boolean
  refundAmount?: number,
  refundRate?: number,
  refundMaximum?: number,
  refundMinimum?: number,
  excludeComparison: boolean,
}

type searchSantePrestation = {
  name?: string
}

/*
 * Output
 */

type santePrestation = {
  id: number
  name: string
  order: number
  hide?: boolean
  refundAmount?: number,
  refundRate?: number,
  refundMaximum?: number,
  refundMinimum?: number,
  excludeComparison: boolean,
}

type santePrestationWithCategory = {
  id: number
  name: string
  order: number
  category: santeCategoryPreview
}

type deleteSantePrestation = {
  id: number
}

export {
  searchSantePrestation,
  santePrestation,
  santePrestationWithCategory,
  santePrestationSearch,
  santePrestationCreate,
  santePrestationUpdate,
  deleteSantePrestation,
}
