/**
 * Makes the first letter of a string uppercase
 *
 * @returns strings with first letter uppercase
 */
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

/**
 * Test if an array of objects contains a certain object
 *
 * @returns true if array contains given object, false otherwise
 */
Array.prototype.containsObject = function (obj) {
  return this.some(function (elem) {
    return elem === obj
  })
}
