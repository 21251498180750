import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { abonnementPreview, abonnementSateEnum } from './abonnement.module.api'
import { rolePreview } from './role.module.api'

export default class UserModuleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: string): Promise<ApiResult<user>> {
    return this.instance.get(`/User/${id}`)
  }

  async getFull(id: string): Promise<ApiResult<userFull>> {
    return this.instance.get(`/User/${id}/full`)
  }

  async linkRole(userRole: linkRole): Promise<ApiResult<idItem>> {
    return this.instance.post('/User/Role', userRole)
  }

  async linkAbonnement(userAbonnement: linkAbonnement): Promise<ApiResult<idItem>> {
    return this.instance.post('/User/Abonnement', userAbonnement)
  }

  async removeRole(userRole: linkRole): Promise<ApiResult<idItem>> {
    return this.instance.delete('/User/Role', { data: userRole })
  }

  async removeAbonnement(userAbonnement: linkAbonnement): Promise<ApiResult<idItem>> {
    return this.instance.delete('/User/Abonnement', { data: userAbonnement })
  }

  async create(newUser: userCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/User/', newUser)
  }

  async update(user: userUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/User/', user)
  }

  async search(search: userSearch): Promise<ApiResult<Pagging<userPreviewWithRoleAbonnement>>> {
    return this.instance.post('/User/Search', search)
  }

  async delete(id: string): Promise<ApiResult<Pagging<userPreviewWithRoleAbonnement>>> {
    return this.instance.delete('/User', { params: { id: id } })
  }

  async reset(id: { id: string }): Promise<ApiResult<string>> {
    return this.instance.post('/user/reset', id)
  }
}

// Enums
enum userSortByEnum {
  Id = 'Id',
  Username = 'Username',
  Email = 'Email',
  LastLoginTime = 'LastLoginTime',
  Company = 'Company',
  AbonnementEnd = 'abonnements'
}
// Types
type user = {
  id: string
  userName: string
  email: string
  firstName?: string
  lastName?: string
  company?: string
  emailConfirmed: boolean
  twoFactorEnabled: boolean
  lockoutEnabled: boolean
  companyName?: string
  activityOfCompany?: string
  companySize?: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  jobInCompany?: string
  phone?: string
  lastLoginTime?: string
  registrationDate?: string
  lockoutEnd: string
}
type userFull = {
  id: string
  userName: string
  email: string
  firstName: string
  lastName: string
  companyId?: string
  companyName?: string
  emailConfirmed: boolean
  twoFactorEnabled: boolean
  lockoutEnabled: boolean
  inputCompanyName?: string
  activityOfCompany?: string
  companySize?: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  jobInCompany?: string
  phone?: string
  lastLoginTime?: string
  registrationDate?: string
  lockoutEnd?: string
  roles: { id: string; name: string }[]
  abonnements: { id: string; name: string; start: string; end: string }[]
}
type linkRole = {
  id: string
  roleName: string
}
type linkAbonnement = {
  id: string
  abonnementId: string
  start?: string
  end?: string
}
type userCreate = {
  userName: string
  email: string
  firstName?: string
  lastName?: string
  company?: string
  companyName?: string
  activityOfCompany?: string
  companySize?: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  jobInCompany?: string
  phone?: string
}
type userUpdate = {
  id: string
  userName?: string
  email?: string
  firstName?: string
  lastName?: string
  companyId?: string
  emailConfirmed?: true
  twoFactorEnabled?: true
  lockoutEnd?: string
  inputCompanyName?: string
  activityOfCompany?: string
  companySize?: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  jobInCompany?: string
  phone?: string
  unlock?: boolean
}
type userSearch = {
  email?: string
  userName?: string
  page: number
  limit: number
  orderByDes?: boolean
  sortBy?: string
  company?: string
  allRoleMatch?: boolean
  allAbonnementMatch?: boolean
  ids?: string[]
  roles?: string[]
  abonnements?: string[]
  abonnementState?: abonnementSateEnum
}
type userPreviewWithRoleAbonnement = {
  id: string
  userName: string
  email: string
  lastLoginTime: string
  company?: string
  roles: rolePreview[]
  abonnements: abonnementPreview[]
}

export {
  user,
  userFull,
  userCreate,
  userUpdate,
  userSearch,
  userPreviewWithRoleAbonnement,
  userSortByEnum,
  linkAbonnement,
}
