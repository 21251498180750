import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api Interface
export default class AbonnementModuleApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(name: string): Promise<ApiResult<Pagging<abonnement>>> {
    return this.instance.post('/Abonnement/Search', { limit: 1, page: 1, name: name })
  }

  async create(newAbonnement: abonnementCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/Abonnement/', newAbonnement)
  }

  async update(abonnement: abonnementUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/Abonnement/', abonnement)
  }

  async search(search: abonnementSearch): Promise<ApiResult<Pagging<abonnementPreview>>> {
    return this.instance.post('/Abonnement/Search', search)
  }

  async delete(id: string): Promise<ApiResult<idItem>> {
    return this.instance.delete('/Abonnement', { params: { id } })
  }
}

// Enums
enum abonnementSateEnum {
  All,
  Valid,
  Expired,
  Comming
}

// Types
type abonnement = {
  id: string
  name: string
}
type abonnementPreview = {
  id: string
  name: string
}
type abonnementCreate = {
  name: string
}
type abonnementUpdate = {
  id: string
  name: string
}
type abonnementSearch = {
  orderByDescending?: boolean
  page: number
  limit: number
  name?: string
}

export { abonnement, abonnementPreview, abonnementCreate, abonnementUpdate, abonnementSearch, abonnementSateEnum }
