import { apiServer } from '@/api/server.api'
import { env } from '@/env'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
  },
  {
    path: '/ccns',
    name: 'ccns-all',
    component: () => import('@/views/Data/Convention/ConventionTable.vue'),
  },
  {
    path: '/apes',
    name: 'apes-all',
    component: () => import('@/views/Data/Ape/ApeTable.vue'),
  },
  {
    path: '/data/nomenclature/sante',
    name: 'nomenclature-sante',
    component: () => import('@/views/Data/Nomenclature/Sante/NomenclatureSanteTable.vue'),
  },
  {
    path: '/data/nomenclature/salaire',
    name: 'nomenclature-salaire',
    component: () => import('@/views/Data/Nomenclature/Salaire/NomenclatureSalaireTable.vue'),
  },
  {
    path: '/data/nomenclature/prevoyance',
    name: 'nomenclature-prevoyance',
    component: () => import('@/views/Data/Nomenclature/Prevoyance/NomenclaturePrevoyanceTable.vue'),
  },
  {
    path: '/data/nomenclature/retraite',
    name: 'nomenclature-retraite',
    component: () => import('@/views/Data/Nomenclature/Retraite/NomenclatureRetraiteTable.vue'),
  },
  {
    path: '/data/unites/:type',
    name: 'unites',
    component: () => import('@/views/Data/Unite/UniteTable.vue'),
  },
  {
    path: '/data/cibles/:type',
    name: 'cibes',
    component: () => import('@/views/Data/Cible/CibleTable.vue'),
  },
  {
    path: '/data/updatePartenaire',
    name: 'updatePartenaire',
    beforeEnter(to, from, next) {
      window.location.href = (env.VUE_APP_TRIPALIO_APP_URL ?? "http://localhost:5051/") + "admin/updatePartenaire"
    }
  },
  {
    path: '/data/covea-export',
    name: 'covea-export',
    component: () => import('@/views/Data/Externe/ExportCovea.vue'),
  },
  {
    path: '/data/boutique',
    name: 'boutique',
    component: () => import('@/views/Data/Boutique/Boutique.vue'),
  },
  {
    path: '/data/article/:id',
    name: 'article',
    component: () => import('@/views/Data/Boutique/article.vue'),
  },
  {
    path: '/users',
    name: 'Utilisateurs',
    component: () => import('@/views/User/Liste/ListeTable.vue'),
  },
  {
    path: '/Entreprises',
    name: 'Entreprises',
    component: () => import('@/views/User/Company/CompanyTable.vue'),
  },
  {
    path: '/roles',
    name: 'Rôles',
    component: () => import('@/views/User/Role/RoleTable.vue'),
  },
  {
    path: '/abonnements',
    name: 'Abonnements',
    component: () => import('@/views/User/Abonnement/AbonnementTable.vue'),
  },
  {
    path: '/achats',
    name: 'Achats',
    component: () => import('@/views/User/Achat/AchatTable.vue'),
  },
  {
    path: '/unauthorized',
    name: 'Login',
    meta: {
      layout: 'empty',
      skipAuth: true,
    },
    component: () => import('@/views/UnAuthorized.vue'),
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/logout') {
    apiServer.auth.logout();
    // next({ })
    // localStorage.removeItem('access_token')
    // localStorage.removeItem('refresh_token')
    // next({ path: 'unauthorized' })
    return
  }

  if (to.path === '/login') {
    apiServer.auth.authorize()
    return
  }

  if (to.path === '/callback') {
    apiServer.auth.token(to.query.code as string, to.query.state as string).then(() =>  next({ path: '/' }))
    return
  }

  if (to.matched.some((m) => !m.meta.skipAuth)) {
    const hasAuthCookie = /Authorization=(.+?)(;|$)/.test(decodeURIComponent(document.cookie))

    if (!hasAuthCookie && !localStorage.getItem('access_token')) {
      next({ path: 'unauthorized' })
      return
    }
  }
  next()
})

export default router
