import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { retraitePrestationSearch } from './retraitePrestation.module.api'

// Api interface
export default class RetraiteNomenclatureApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<retraiteNomenclature>> {
    return this.instance.get(`/RetraiteNomenclature/${id}`)
  }

  async getWithPrestation(id: number): Promise<ApiResult<retraiteNomenclatureWithPrestation>> {
    return this.instance.get(`/RetraiteNomenclature/${id}/prestation`)
  }

  async getByIdAndCibleIdAndCcnId(
    id: number,
    cibleId: number,
    ccnId: number
  ): Promise<ApiResult<retraiteNomenclatureWithPrestation>> {
    return this.instance.get(`/RetraiteNomenclature/${id}/prestation/accord/all/${cibleId}/${ccnId}`)
  }

  async getNomenclatureAndCible(search: retraitePrestationSearch): Promise<ApiResult<Pagging<retraiteNomenclatureAndCible>>> {
    return this.instance.post('/Cibles/retraite_search', search)
  }

  async search(search: retraiteNomenclatureSearch): Promise<ApiResult<Pagging<retraiteNomenclature>>> {
    return this.instance.post('/RetraiteNomenclature/search', search)
  }

  async create(newRetraiteNomenclature: retraiteNomenclatureCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/RetraiteNomenclature', newRetraiteNomenclature)
  }

  async modify(retraiteNomenclature: retraiteNomenclatureUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/RetraiteNomenclature', retraiteNomenclature)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/RetraiteNomenclature', { params: { id } })
  }
}

// Types
type retraiteNomenclatureSearch = {
  page: number
  limit: number
  name?: string
  id?: number
}

type retraiteNomenclatureCreate = {
  name: string
}

type retraiteNomenclatureUpdate = {
  id: number
  name: string
}

/*
 * Output
 */
type retraiteNomenclature = {
  id: number
  name: string
}

type retraiteNomenclatureWithPrestation = {
  nomenclature: retraiteNomenclature
  prestations: any
}

type retraiteNomenclatureAndCible = {
  nomenclatureId: number
  nomenclatureName: string
  ccnId: number
  cibleId: number
  cibleName: string
}

export {
  retraiteNomenclatureWithPrestation,
  retraiteNomenclatureSearch,
  retraiteNomenclatureCreate,
  retraiteNomenclatureUpdate,
  retraiteNomenclature,
  retraiteNomenclatureAndCible,
}
