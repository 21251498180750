import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { salaireCategoryPreview } from './salaireCategory.modue.api'

// Api interface
export default class SalairePrestationApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(config: searchSalairePrestation): Promise<ApiResult<Pagging<salairePrestationWithCategory>>> {
    return this.instance.post('/SalairePrestation/search', config)
  }

  async create(newSalairePrestation: salairePrestationCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SalairePrestation', newSalairePrestation)
  }

  async modify(salairePrestation: salairePrestationUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SalairePrestation', salairePrestation)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SalairePrestation', { params: { id } })
  }
}

// Types
type salairePrestationSearch = {
  name?: string
  page: number
  limit: number
}

type salairePrestationCreate = {
  categoryId: number
  name: string
  order: number
}

type salairePrestationUpdate = {
  id: number
  categoryId: number
  name: string
  order: number
  hide?: boolean
}

type searchSalairePrestation = {
  name?: string
}

/*
 * Output
 */

type salairePrestation = {
  id: number
  name: string
  order: number
  hide?: boolean
}

type salairePrestationWithCategory = {
  id: number
  name: string
  order: number
  category: salaireCategoryPreview
}

type deleteSalairePrestation = {
  id: number
}

export {
  searchSalairePrestation,
  salairePrestation,
  salairePrestationWithCategory,
  salairePrestationSearch,
  salairePrestationCreate,
  salairePrestationUpdate,
  deleteSalairePrestation,
}
