<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="60"
    flat
    :dark="true"
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-light text-h5" v-text="this.$route.name" />

    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" style="padding-top: 5px"/> -->

    <!-- <default-notification /> -->

    <v-btn @click="redirectToApp">Revenir sur Tripalio</v-btn>

    <default-account />
  </v-app-bar>
</template>

<script>
import { env } from '@/env'
import Vue from 'vue'
import { sync } from 'vuex-pathify'
import DefaultAccount from './Account'
import DefaultDrawerToggle from './DrawerToggle'

export default Vue.extend({
  name: 'DefaultBar',

  components: {
    DefaultAccount,
    DefaultDrawerToggle,
  },

  computed: {
    ...sync(['mini', 'drawer']),
  },

  methods: {
    redirectToApp() {
      window.location.href = env.VUE_APP_TRIPALIO_APP_URL
    },
  },
})
</script>
