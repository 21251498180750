import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'
import { santePrestationSearch } from './santePrestation.module.api'

// Api interface
export default class SanteNomenclatureApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async get(id: number): Promise<ApiResult<santeNomenclature>> {
    return this.instance.get(`/SanteNomenclature/${id}`)
  }

  async getWithPrestation(id: number): Promise<ApiResult<santeNomenclatureWithPrestation>> {
    return this.instance.get(`/SanteNomenclature/${id}/prestation`)
  }

  async getByIdAndCibleIdAndCcnId(
    id: number,
    cibleId: number,
    ccnId: number
  ): Promise<ApiResult<santeNomenclatureWithPrestation>> {
    return this.instance.get(`/SanteNomenclature/${id}/prestation/accord/all/${cibleId}/${ccnId}`)
  }

  async getNomenclatureAndCible(search: santePrestationSearch): Promise<ApiResult<Pagging<santeNomenclatureAndCible>>> {
    return this.instance.post('/Cibles/sante_search', search)
  }

  async search(search: santeNomenclatureSearch): Promise<ApiResult<Pagging<santeNomenclature>>> {
    return this.instance.post('/SanteNomenclature/search', search)
  }

  async create(newSanteNomenclature: santeNomenclatureCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/SanteNomenclature', newSanteNomenclature)
  }

  async modify(santeNomenclature: santeNomenclatureUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/SanteNomenclature', santeNomenclature)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/SanteNomenclature', { params: { id } })
  }
}

// Types
type santeNomenclatureSearch = {
  page: number
  limit: number
  name?: string
  id?: number
}

type santeNomenclatureCreate = {
  name: string
}

type santeNomenclatureUpdate = {
  id: number
  name: string
}

/*
 * Output
 */
type santeNomenclature = {
  id: number
  name: string
}

type santeNomenclatureWithPrestation = {
  nomenclature: santeNomenclature
  prestations: any
}

type santeNomenclatureAndCible = {
  nomenclatureId: number
  nomenclatureName: string
  ccnId: number
  cibleId: number
  cibleName: string
}

export {
  santeNomenclatureWithPrestation,
  santeNomenclatureSearch,
  santeNomenclatureCreate,
  santeNomenclatureUpdate,
  santeNomenclature,
  santeNomenclatureAndCible,
}
