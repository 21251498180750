import { AxiosInstance } from 'axios'
import { ApiResult, idItem } from '../server.api'

// Api interface
export default class PrevoyanceCategoryApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(newPrevoyanceCategory: prevoyanceCategorySearch): Promise<ApiResult<any>> {
    return this.instance.post('/PrevoyanceCategory/search', newPrevoyanceCategory)
  }

  async create(newPrevoyanceCategory: prevoyanceCategoryCreate): Promise<ApiResult<idItem>> {
    return this.instance.post('/PrevoyanceCategory', newPrevoyanceCategory)
  }

  async modify(prevoyanceCategory: prevoyanceCategoryUpdate): Promise<ApiResult<idItem>> {
    return this.instance.put('/PrevoyanceCategory', prevoyanceCategory)
  }

  async delete(id: number): Promise<ApiResult<idItem>> {
    return this.instance.delete('/PrevoyanceCategory', { params: { id } })
  }
}

// Types
type prevoyanceCategoryCreate = {
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type prevoyanceCategoryUpdate = {
  id: number
  name: string
  nomenclatureId?: number // Si à la racine, parentId
  parentId?: number // Sinon, nomenclatureId
  order: number
}

type prevoyanceCategorySearch = {
  nomenclatureId?: number
  page: number
  limit: number
}

type prevoyanceCategoryPreview = {
  id: number
  name: string
  order: number
}

type deletePrevoyanceCategory = {
  id: number
}

type prevoyanceCategory = prevoyanceCategoryUpdate

export {
  prevoyanceCategoryCreate,
  prevoyanceCategoryUpdate,
  prevoyanceCategory,
  prevoyanceCategorySearch,
  deletePrevoyanceCategory,
  prevoyanceCategoryPreview,
}
